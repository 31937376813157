@use "../../../styles/_colors-variables.scss";

// .modal-register-edit-organization
.modal-default {
  &.modal-register-edit-organization {
    background-color: rgba(0, 0, 0, 0.2);
    display: block;
    .modal-mains {
      width: 600px;
      height: auto;
      padding-bottom: 30px;
      margin: 60px auto;
      .btn-close {
        width: 33px;
        height: 33px;
        &:hover {
          opacity: 0.9;
        }
      }
      .top-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #121863;
        margin-bottom: 24px;
      }
      .trash-question-box {
        position: relative;
        &.open {
          .tip-box {
            display: block;
          }
        }
        .tip-box {
          display: none;
          width: 533px;
          position: absolute;
          top: 58px;
          right: -121px;
          background-color: #fff;
          z-index: 666;
          border: 1px solid #c9d2df;
          box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
          border-radius: 16px;
          padding: 10px 30px 30px;
          ul {
            margin-top: 20px;
            max-height: 500px;
            overflow-y: auto;
          }
          li {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #c9d2df;
            padding: 10px;
          }
        }
        .txt {
          font-weight: 700;
          font-size: 24px;
          line-height: 35px;
          letter-spacing: -0.005em;
          color: #121863;
        }
        .bottom-btns {
          margin-top: 26px;
          .btn {
            height: 40px;
            line-height: 36px;
            min-width: 130px;
          }
        }
      }
      .title-bar {
        margin-bottom: 13px;
        .label-title {
          margin-bottom: 6px;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          color: #7b7b7b;
          line-height: 23px;
          letter-spacing: 0.015em;
        }

        .date-inputs {
          width: 123px;
          .inputs {
            height: 44px;
            font-size: 14px;
            input {
              font-weight: 700;
              color: #121863;
              font-size: 14px;
            }
          }
        }
      }
      .inputs {
        height: 56px;
        border: 1px solid #c4c4c4;
        input {
          line-height: 24px;
        }
      }
      .p-txt {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        color: #121863;
        margin-bottom: 24px;
      }
      .btn-trash {
        width: 24px;
        height: 24px;
        background: url(../../../assets/icon-trash.svg) center center no-repeat;
        background-size: 14px 18px;
      }
      .drop-group-row {
        margin-bottom: 8px;
        .inputs {
          height: 44px;
        }
        .group {
          margin-right: 13px;
          &.first-group {
            max-width: 174px;
            flex: 0 0 174px;
          }
          &.last-group {
            flex: 1;
          }
        }
      }
      .bottom-add {
        display: flex;
        justify-content: flex-end;
        .btn-blue {
          width: 113px;
          line-height: 26px;
          height: 29px;
          font-size: 14px;
          font-weight: 500;
        }
      }
      .register-area {
        margin-top: 30px;
      }
      .input-groups {
        margin: 0 -4px 0;
        .items {
          padding: 0 4px;
          flex: 1;
        }
      }
      .bottom-btns {
        margin-top: 30px;
        .btn {
          min-width: 148px;
          height: 44px;
          line-height: 40px;
          border-radius: 22px;
        }
      }

      .register-organization-input {
        margin-bottom: 6px;
        &.error-txt {
          margin-bottom: 55px;
        }
      }
    }
  }
}
