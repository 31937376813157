@use "../../styles/_responsive-variables.scss";
@use "../../styles/_colors-variables.scss";

.facility-review-summaries {
  // .back-top-bar
  .back-top-bar {
    margin-bottom: 35px;
    .back-link {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      letter-spacing: 0.015em;
      color: #757799;
      .icon-back {
        background: url("/assets/icon-back.svg") center center no-repeat;
        background-size: 10px 9px;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        margin-top: -4px;
      }
    }
    .right-btns {
      display: flex;
      > * {
        margin-left: 16px;
        .icons {
          width: 20px;
          margin-right: 10px;
          height: 18px;
          margin-top: -5px;
        }
        &.btn-blue {
          padding: 1px 26px;
          position: relative;

          &--fade {
            position: relative;
          }
        }
      }

      .to-gbp-button {
        text-align: right;

        small.footnote {
          margin-top: 4px;
        }
      }
    }

    @media #{responsive-variables.$smartphone-breakpoint} {
      flex-direction: column;
      align-items: flex-start;
      gap: 30px;
      margin-bottom: 24px;

      .right-btns {
        display: flex;
        flex-direction: column-reverse;

        > * + * {
          margin-bottom: 10px;
        }

        .btn {
          margin: 0;
        }
        .to-gbp-button {
          margin-left: 0;
          gap: 4px;
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-start;

          a.btn-blue {
            padding-left: 10px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
// .big-bold-title
.big-bold-title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 32px;
  line-height: 130%;
  letter-spacing: -0.015em;
  color: #121863;
  .letter-square {
    margin-left: 10px;
    font-size: 32px;
    line-height: 36px;
    border-radius: 6px;
    min-width: 36px;
  }
}
.sub-title {
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.005em;
  font-weight: 700;
  margin-bottom: 24px;
  color: #121863;
}
@media #{responsive-variables.$smartphone-breakpoint} {
  .flex-grid:has(.big-bold-title) {
    // Title section
    flex-direction: column;
    align-items: flex-start;

    > .big-bold-title {
      margin-bottom: 8px;
      font-size: responsive-variables.$sp-title-size;

      & + .rights {
        .big-bold-title {
          font-size: responsive-variables.$sp-h2-size;

          .letter-square {
            margin-left: 6px;
            font-size: responsive-variables.$sp-h2-size;
            line-height: 27px;
            min-width: 27px;
          }
        }
      }
    }
  }
}
.detail-group {
  margin-bottom: 40px;
  .group-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.005em;
    color: #121863;
    margin-bottom: 16px;
    .line {
      margin-left: 15px;
      flex: 1;
      height: 4px;
      background: #c9d2df;
    }
  }
  .two-panel {
    .row {
      margin: 0 -12px;
      .col {
        padding: 0 12px;
      }
    }
    .col {
      .white-border-panel {
        min-height: 100%;
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .group-title {
      font-size: responsive-variables.$sp-h2-size;
    }
    .two-panel {
      > .row {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 24px;
      }

      .row {
        margin: 0;

        .col {
          padding: 0;
        }
      }
    }
  }
}

.analytics-module {
  @media #{responsive-variables.$smartphone-breakpoint} {
    button.btn {
      width: 100%;
      display: block;
    }
  }
}

small.footnote {
  display: block;
  font-size: 12px;
  text-align: right;
  &::before {
    content: "* ";
  }
}
