@use "../../styles/_colors-variables.scss";

// .left-nav
.bottom-nav {
  background: #f5f5ff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  padding-top: 8px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      margin: 0 8px;
      width: 80px;

      button {
        background-color: transparent;
      }
    }
  }
  .nav-items {
    padding: 8px 11px;
    border-radius: 18px;
    border: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 40px;
    &.active {
      background-color: #3241ff;
      color: #fff;
      border-radius: 20px 20px 0 0;

      .txt {
        color: white;
      }
      .icon-dashboard {
        background: url("/assets/icon-dashboard-white.svg") center center
          no-repeat;
        background-size: 18px 18px;
      }
      .icon-chart {
        background: url("/assets/chart-white.svg") center center no-repeat;
        background-size: 18px 18px;
      }
      .icon-build {
        background: url("/assets/build-white.svg") center center no-repeat;
        background-size: 21px 18px;
      }
      .icon-suggestions {
        background: url("/assets/user-talk-white.svg") center center no-repeat;
        background-size: 20px 18px;
      }
      .icon-setting {
        background: url("/assets/setting-white.svg") center center no-repeat;
        background-size: 20px 20px;
      }
      .icon-user-circle {
        background: url("/assets/user-circle-white.svg") center center no-repeat;
        background-size: 20px 20px;
      }
      .icon-list {
        background: url("/assets/icon-list-white.svg") center center no-repeat;
        background-size: 18px 10px;
      }
      .icon-trash {
        background: url("/assets/icon-trash-white.svg") center center no-repeat;
        background-size: 14px 18px;
      }
      .icon-email {
        background: url("/assets/email-white.svg") center center no-repeat;
        background-size: 20px 16px;
      }
    }
    .icons {
      width: 22px;
      height: 20px;
      flex: 0 0 22px;
      max-width: 22px;
    }
    .txt {
      color: #3241ff;
      font-weight: 700;
      font-size: 12px;
      white-space: nowrap;
    }

    .icon-dashboard {
      background: url("/assets/icon-dashboard.svg") center center no-repeat;
      background-size: 18px 18px;
    }
    .icon-chart {
      background: url("/assets/chart.svg") center center no-repeat;
      background-size: 18px 18px;
    }
    .icon-build {
      background: url("/assets/build.svg") center center no-repeat;
      background-size: 21px 18px;
    }
    .icon-suggestions {
      background: url("/assets/user-talk.svg") center center no-repeat;
      background-size: 20px 18px;
    }
    .icon-setting {
      background: url("/assets/setting.svg") center center no-repeat;
      background-size: 20px 20px;
    }
    .icon-user-circle {
      background: url("/assets/user-circle.svg") center center no-repeat;
      background-size: 20px 20px;
    }
    .icon-list {
      background: url("/assets/icon-list.svg") center center no-repeat;
      background-size: 18px 10px;
    }

    .icon-trash {
      background: url("/assets/icon-trash-blue.svg") center center no-repeat;
      background-size: 14px 18px;
    }

    .icon-email {
      background: url("/assets/email.svg") center center no-repeat;
      background-size: 20px 16px;
    }
  }
}
