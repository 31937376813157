@use '../../../styles/_colors-variables.scss';

.modal-default.modal-send-message-confirm {
    .modal-mains {
        max-height: 70vh;

        h3 {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
        }

        p {
            margin: 8px 0 16px;
            white-space: pre-line;
        }
    }

    .modal-send-message-body {
        max-height: calc(70vh - 80px);
    }
}