@use "../../../../styles/_responsive-variables.scss";

.download-reviews-button {
  &__buttons {
    display: flex;
    gap: 8px;

    @media #{responsive-variables.$smartphone-breakpoint} {
      flex-direction: column;
      align-items: stretch;
    }
  }
}
