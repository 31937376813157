@use "../../../styles/_responsive-variables.scss";

.qr-code-display-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 260px;

  .qr-title {
    font-size: 16px;
    font-weight: bold;
    width: fit-content;
  }

  .qr-url {
    word-break: break-all;
    font-size: 12px;
    max-width: 260px;

    @media #{responsive-variables.$smartphone-breakpoint} {
    }
  }

  .qr-download-link {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-saveload-blue {
      background: url(/assets/saveload-blue.svg) no-repeat center;
      background-size: 22px 22px;
      width: 22px;
      height: 22px;
    }
  }

  .qr-code-display-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
  }

  canvas {
    max-width: 180px;
    max-height: 180px;
  }

  .message {
    font-size: 14px;
    max-width: 200px;
  }
}
