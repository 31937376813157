@use '../../../styles/_colors-variables.scss';

// .dashboard-metric-pie-chart-module
.dashboard-metric-pie-chart-module {
    .panel-title {
        font-family: 'Noto Sans JP';
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 10px;
    }
    .chart-area {
        margin-top: 10px;
        margin-bottom: 58px;
        display: flex;
        justify-content: center;
    }
}