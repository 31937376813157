@use "../../../styles/_colors-variables.scss";

div.special-operation-hours-current {
  display: flex;
  flex-direction: column;
  gap: 24px;

  div.special-operation-hours-current-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    padding: 12px;
    border-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  }
}
