@use "../../../../../styles/_colors-variables.scss";

.local-post-preview-wrapper {
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  .top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .location-title {
      display: flex;
      align-items: center;
      gap: 8px;
      .location-logo {
        width: 42px;
        height: 42px;
        background-color: colors-variables.$themeBlue;
      }

      .location-name-wrapper {
        display: flex;
        flex-direction: column;
        line-height: 110%;

        .location-name {
          font-weight: bold;
        }

        .post-time {
          font-weight: lighter;
          font-size: small;
        }
      }
    }

    .options {
      width: 30px;
      height: 30px;
      background: url("/assets/vertical-dots.svg") center center no-repeat;
      background-size: 30px auto;
      cursor: pointer;
    }
  }

  .image-wrapper {
    background-color: black;
    display: flex;
    justify-content: center;
    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 300px;
    }
  }

  .event-details {
    display: flex;
    flex-direction: row;
    padding: 16px;
    .left {
      flex-grow: 1;
      .event-title {
        color: black;
        font-size: x-large;
      }

      .event-schedule {
        color: black;
        font-size: large;
      }
    }
    .offer-tag {
      width: 30px;
      height: 30px;
      background: url("/assets/offer-tag.svg") center center no-repeat;
      background-size: 30px auto;
    }
  }

  .text-body {
    padding: 16px;
    white-space: pre-wrap;
  }

  .terms {
    padding: 16px;
    border-top: 1px solid #bcbcbe;
    color: #bcbcbe;
    font-size: small;
    line-height: 110%;
  }

  .share {
    width: 100%;
    height: 46px;
    background: url("/assets/share.svg") center center no-repeat;
    background-size: 30px;
    background-position: right 16px center;
    cursor: pointer;
  }
}
