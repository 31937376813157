form.hook-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    button {
      min-width: 160px;
    }
  }

  h2 {
    font-size: 14px;
    font-weight: 600;
  }
}
