@use "../../styles/responsive-variables";
@use "../../styles/_colors-variables.scss";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 23px;
  height: 64px;
  background: #3241ff;
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  position: relative;
  .left-logo {
    .btn-logo {
      width: 230px;
      height: 24px;
      background: url("/assets/logo.svg") no-repeat;
      background-size: 230px 24px;
      display: block;
    }
  }
  .right-link {
    .lists {
      display: flex;
      align-items: center;
      .link {
        margin-left: 40px;
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        color: colors-variables.$white;
      }
    }
    .btn-logout {
      margin-left: 33px;
      .icon-logout {
        width: 18px;
        height: 18px;
        background: url("/assets/logout.svg") no-repeat;
        background-size: 18px 18px;
        margin-right: 5px;
      }
      .txt {
        font-weight: 700;
        font-size: 16px;
        color: white;
      }
    }
  }

  button {
    background: none;
    border: none;
  }

  @media #{responsive-variables.$tablet-breakpoint} {
    li.list-item,
    .btn-logout {
      display: none;
    }
  }
}
