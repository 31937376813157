@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";
@use "../../../styles/_button-style-mixin.scss";

.internal-tools-header {
  .internal-tools-login {
    margin-bottom: 16px;
  }
}

.internal-tool-tools {
  position: relative;

  div.internal-tool:not(:last-child) {
    margin-bottom: 40px;
  }
}

.internal-tools-tools {
  * {
    font-family: "Noto Sans JP", sans-serif;
    color: colors-variables.$textColor;
  }

  h1 {
    margin: 0px 0px 16px !important; //override default from ./src/styles/_reset.scss
    font-size: 24px !important; // override default from node_modules/bootstrap/scss/vendor/_rfs.scss
    font-weight: 700 !important; //override user agent stylesheet
    line-height: 130%;
    letter-spacing: -0.015em;
  }

  h2 {
    display: flex;
    align-items: center;
    font-weight: 700 !important; //override user agent stylesheet
    font-size: 18px !important; //override default from node_modules/bootstrap/scss/vendor/_rfs.scss
    line-height: 1.5;
    letter-spacing: -0.005em;

    &::after {
      content: "";
      position: relative;
      top: -3px;
      flex: 1 1 auto;
      height: 3px;
      margin: 32px 40px 16px 24px;
      background: #d7dde7;
    }

    &.big {
      font-size: 36px;
    }
  }

  p {
    display: block;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
}

> section {
  width: 100%;
}

section.card {
  margin-right: 24px;
  background: white;
  border: 1px solid #d7dde7;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 24px 32px 32px 32px;

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }

  > h3,
  > p {
    &:first-child {
      margin-top: 0;
    }
  }

  & + & {
    margin-top: 40px;
  }

  button.internal-tools-button {
    @include button-style-mixin.buttonStyle;
    height: 40px;
    margin-top: 24px;
    border: 0;
    padding: 0 24px;
    min-width: 150px;
  }

  input {
    border: 1px solid #c4c4c4;
    border-radius: 8px;
    padding: 16px 9px;
    color: colors-variables.$themeBlue;
    margin-right: 16px;
    width: fit-content;
  }

  a {
    text-decoration: underline;
  }

  #locationName,
  #extraKeywords {
    width: fit-content;
  }

  #error {
    white-space: break-spaces;
    color: red;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;

    &.showError {
      + #error-retry {
        display: block;

        button {
          margin-top: 0;
        }
      }
    }
  }
}

#interactionArea {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(40, 40, 40, 0.8);
  opacity: 0.7;
  cursor: not-allowed;
}

#loading-text {
  position: absolute;
  font-size: 60px;
  font-weight: 700;
  color: white;
  text-shadow: 0 0 4px colors-variables.$themeBlue;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: pre-wrap;
  width: 80%;
  text-align: center;
  opacity: 1;

  @media #{responsive-variables.$smartphone-breakpoint} {
    font-size: 30px;
  }
}
