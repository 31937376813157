@use '../../../styles/_colors-variables.scss';

.pie-chart-container {
    position: relative;
    .recharts-wrapper {
        .recharts-layer {
            text {
                top: -20px;
                right: -40px;
                position: absolute;
                margin-left: -10px;
            }
            &.recharts-pie-labels {
                .recharts-layer {
                    &:first-child {
                       margin-right: -50px; 
                    }
                }
            }
        }
    }

    .chart-center-num {
        border-radius: 100%;
        border: 2.58436px solid #C9C9C6;
        position: absolute;
        top: 47px;
        left: 140px;
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        text-align: center;
        letter-spacing: -0.015em;
        color: #121863;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 220px;
        word-break: break-all;
        padding: 10px;
        &.bigger {
            top: 108px;
            left: 271px;
            width: 208px;
            height: 208px;
        }
        &.medium {
            top: 82px;
            left: 140px;
            width: 220px;
            height: 220px;
        }
        &.smaller {
            top: 77px;
            left: 82px;
            width: 208px;
            height: 208px;
        }
    }
}
