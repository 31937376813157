@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

.dropdown-select {
  &.suggestions-filter-bar-dropdown {
    .dropdown-toggle {
      color: #fff;
      height: 30px;
      font-size: 16px;
      span {
        background: url(/assets/white-drop.svg) center center no-repeat;
        background-size: 10px 7px;
        width: 10px;
        height: 10px;
        margin-top: 2px;
        margin-right: -2px;
      }
    }
  }

  > button.dropdown-toggle.btn {
    @media #{responsive-variables.$smartphone-breakpoint} {
      display: flex;
      align-items: center;
    }
  }
  .dropdown-toggle {
    border-radius: 2px;
    min-width: 100%;
    height: 40px;
    line-height: 1;
    color: #121863;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    border: 0;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 0;
    span {
      background: url(/assets/icon-drop.svg) center center no-repeat;
      background-size: 10px 7px;
      width: 10px;
      height: 10px;
      margin-top: 6px;
      margin-right: -5px;
    }
    &::after {
      content: none;
    }
    .toggle-label {
      margin-right: 16px;
      &.placeholder-label {
        color: #d7dde7;
      }
    }
  }
  .dropdown-menu {
    padding: 0;
    min-width: 100%;
    left: -5px !important;
    width: calc(100% + 34px);
    transform: translate(-12px, 44px) !important;
    max-height: 180px;
    overflow-y: auto;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
    padding: 8px;
    background: rgba(255, 255, 255, 0.98);
    border-radius: 8px;
    width: auto;
    .dropdown-item {
      color: #1f1f1f;
      padding: 10px 16px;
      line-height: 1;
      font-size: 14px;
      border-radius: 8px;
      font-weight: 700;
      &:not(:first-child) {
        margin-top: 4px;
      }
      &:hover,
      &:focus,
      &.active,
      &:active,
      &.selected {
        color: #1f1f1f;
        background-color: rgba(50, 65, 255, 0.1);
      }
    }
  }
}
