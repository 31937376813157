@use "responsive-variables";

@media #{responsive-variables.$smartphone-breakpoint} {
  .recharts-tooltip-item {
    padding: 0 !important;
  }
  .recharts-legend-item,
  .recharts-tooltip-item {
    span {
      font-size: 10px;
    }
  }
}
