@use "../../../styles/_responsive-variables.scss";

.extended-review-summary {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;

  .section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 32px;
    .section-title {
      font-size: 18px;
      font-weight: 700;
      color: #121863;
    }
    ol {
      list-style: decimal;
    }
    ul {
      list-style: disc;
    }
    ol,
    ul {
      display: flex;
      flex-direction: column;
      gap: 8px;
      li {
        display: list-item;
        list-style: unset;
        margin-left: 16px;
      }
    }
  }

  .summary-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 0px;
    @media #{responsive-variables.$smartphone-breakpoint} {
      flex-direction: column;
      align-items: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: stretch;
      align-items: flex-start;
      gap: 48px;
      max-width: fit-content;
      max-width: 800px;
      .content-section {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        align-items: flex-start;
        gap: 8px;
      }
    }
    .chart-section {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
