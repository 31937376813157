@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

.external-integrations {
  .data-group {
    word-break: break-all;
    .data-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      letter-spacing: 0.015em;
      color: #121863;
      margin-bottom: 12px;
    }

    .buttons {
      display: flex;
      gap: 8px;
      flex-wrap: wrap;
    }

    .btn {
      display: flex;
      align-items: center;

      .icon-instagram {
        width: 24px;
        height: 24px;
        background: url("/assets/instagram.png") center center no-repeat;
        background-size: 24px 24px;
        margin-right: 4px;
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .row {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: 1fr;
    }
    .data-group {
      .values {
        font-size: responsive-variables.$sp-main-text-size;
      }
    }
  }
}
