@use "../../../../styles/_responsive-variables.scss";

.modal-default.modal-preview-local-post {
  .modal-mains {
    .top-title {
      padding: 0 16px;
    }
    .modal-mains__body {
      display: flex;
      flex-direction: column;
      gap: 40px;
      padding: 30px 30px !important;
      max-height: 80svh;
      overflow-y: auto;
      overflow-x: hidden;

      .bottom-btns {
        margin-top: 10px;
        justify-content: center;
        gap: 8px;
        div {
          display: flex;
          gap: 8px;
        }
      }

      .post-preview-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    @media #{responsive-variables.$smartphone-breakpoint} {
      .modal-mains__body {
        padding: 16px !important;
      }
    }
  }
}
