@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

// .modal-edit-account-information
.modal-default.modal-edit-account-information {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-mains {
    margin: 50px auto 50px;
    width: 600px;
    height: auto;
    padding: 40px;
    .btn-close {
      width: 33px;
      height: 33px;
      &:hover {
        opacity: 0.9;
      }
    }
    .bottom-btns {
      .btn {
        min-width: 160px;
      }
    }

    .top-title {
      .rights {
        .icon-del {
          background: url(/assets/blue-del.svg) center no-repeat;
          background-size: 18px 24px;
          width: 18px;
          height: 24px;
          margin-left: 32px;
        }
      }
    }
    .row-form {
      margin: 0 -8px;
      .col {
        padding: 0 8px;
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .modal-mains {
      padding: 16px;

      @include responsive-variables.responsiveBottomBtns;
    }
  }
}
