@use "../../../styles/_colors-variables.scss";

.modal-default.modal-new-analysis {
  background-color: rgba(0, 0, 0, 0.2);

  .modal-mains {
    width: 900px;
    height: auto;
    padding: 32px 40px;
    .btn-close {
      width: 40px;
      height: 40px;
      &:hover {
        opacity: 0.9;
      }
    }

    .search-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .search-input {
        width: 35%;
        margin-right: 10px;
      }
    }

    &__main {
      max-height: 90vh;
      overflow: scroll;
    }
    .location-list {
      display: grid;
      grid-gap: 5px 5px;
      overflow: auto;
      height: 170px;
      scrollbar-width: thin;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .language-list-container {
      border: 1px solid #c9d2df;
      border-radius: 8px;
      height: "fit-content";
      padding: 10px;

      .language-list {
        margin-top: 10px;
        display: grid;
        margin-bottom: 20px;
        grid-gap: 12px 15px;
        height: "fit-content";
        grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
      }

      .select-date-picker-container {
        max-width: 450px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        .input-date-picker {
          border-radius: 8px;
          border: 1px solid #c9d2df;
          padding: 0 16px;
          min-height: 50px;
        }
      }
    }

    .bottom-btns {
      margin-top: 0;
      .btn {
        min-width: 160px;
      }
    }
    .row-form {
      margin-bottom: 40px;
      &.row {
        margin: 0 -8px 40px;
      }
      .col {
        padding: 0 8px;
      }
      &.mb60 {
        margin-bottom: 30px;
      }
    }
    .select-date-picker-container {
      max-width: 450px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .input-date-picker {
        border-radius: 8px;
        border: 1px solid #c9d2df;
        padding: 0 16px;
        min-height: 40px;
      }
    }
    .select-locations-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .location-list {
        ul {
          display: grid;
          grid-gap: 12px 15px;
          grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
        }
      }
    }
  }

  .check-lang {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .items {
      margin: 0 5px;
    }
  }
}
