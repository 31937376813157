@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

.modal-local-post-ideas {
  .modal-mains {
    .top-title {
      margin-bottom: 0 !important;
    }
  }
  .modal-mains__body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .description-container {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .description {
        font-size: 14px;
        font-weight: bold;
      }

      .cant-find-post {
        font-size: 12px;
      }
    }
  }
  .sub-section {
    .sub-section-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
    .line {
      background: #c9d2df;
      flex: 1;
      height: 4px;
    }
    .local-post-list {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
    }
  }

  .bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;

    button {
      min-width: 160px;
    }
  }

  h2 {
    padding-left: 0 !important;
  }

  h3 {
    padding-left: 0 !important;
    color: colors-variables.$textColor;
    font-size: 20px !important;
  }
}

@media #{responsive-variables.$smartphone-breakpoint} {
  .modal-local-post-ideas {
    .bottom-buttons {
      flex-direction: column;
    }
  }
}
