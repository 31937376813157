@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

.right-date-wrap {
  position: relative;
  &.open {
    .date-panel {
      display: block;
    }
    .date-drop {
      .btn-gray-border {
        .icon-drop {
          transform: rotateX(180deg);
        }
      }
    }
  }
  .date-drop {
    .btn-gray-border {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 256px;
      height: 44px;
      border: 1px solid #c9d2df;
      border-radius: 8px;
      line-height: 44px;
      padding: 10px 14px;
      .bold {
        font-weight: 700;
        font-size: 16px;
        letter-spacing: 0.015em;
        color: #121863;
        margin-right: 8px;
      }
      .date-txt {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        display: flex;
        letter-spacing: 0.01em;
        color: #121863;
      }
      .icon-drop {
        flex: 0 0 10px;
        max-width: 10px;
        width: 10px;
        height: 7px;
        background: url(/assets/icon-drop.svg) no-repeat center;
        background-size: 10px 7px;
        margin-left: 11px;
        transform: rotateX(0deg);
      }
      @media #{responsive-variables.$smartphone-breakpoint} {
        display: grid;
        height: auto;
        grid-template-columns: 1fr 10px;

        span.bold {
          line-height: 1;
        }

        .icon-drop {
          grid-column: 2;
          grid-row: 1 / span 2;
          margin-left: 0;
        }
      }
    }
  }
  .date-panel {
    display: none;
    .date-range-area {
      display: flex;
      flex-direction: row;
      padding: 16px 24px;
      gap: 32px;
      position: absolute;
      min-width: 940px;
      height: auto;
      right: 0;
      top: 50px;
      background: #ffffff;
      box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      z-index: 1;
      .line {
        width: 1px;
        height: 231px;
        background: #c9d2df;
        border: 1px solid #c9d2df;
      }
      .left-calendar {
        flex: 1;
        display: flex;
        &.disabled {
          .react-datepicker__month {
            pointer-events: none;
            opacity: 0.5;
          }
        }
        .left-arrow,
        .right-arrow {
          background: url(/assets/left-arrow.svg) no-repeat center;
          background-size: 6px 11px;
          width: 24px;
          height: 24px;
        }
        .right-arrow {
          transform: rotateY(-180deg);
        }
        .calendar-items {
          flex: 1;
        }
      }
      .from-area {
        flex: 0 0 35%;
        max-width: 35%;
        .period-select {
          margin-bottom: 15px;
          .label-txt {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.015em;
            color: #121863;
            margin-right: 8px;
          }
          .inputs {
            width: 146px;
            .toggle-label {
              color: #121863;
            }
            .dropdown-menu {
              width: 146px;
              padding: 16px 8px;
              .dropdown-item {
                color: #121863;
                padding: 11px 16px;
              }
            }
          }
        }
        .date-to {
          display: flex;
          align-items: center;
          padding-bottom: 24px;
          .inputs {
            height: 44px;
            input {
              line-height: 20px;
              font-size: 14px;
              font-weight: 700;
              color: #121863;
            }
          }
          .to {
            font-weight: 700;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.0015em;
            color: #121863;
            margin: 0 12px;
          }
        }
        .bottom-btn {
          margin-top: 40px;
          display: flex;
          justify-content: center;
          .btn {
            min-width: 120px;
            margin: 0 4px;
          }
        }
      }

      @media #{responsive-variables.$smartphone-breakpoint} {
        @include responsive-variables.spDefaultPadding;
        min-width: 0;
        width: calc(100% + 36px);
        right: -18px;
        flex-direction: column;
        gap: 16px;

        .left-calendar {
          justify-content: center;
        }

        .line {
          display: none;
        }

        .from-area {
          flex: 1 1 auto;
          width: 100%;
          max-width: none;
          .period-select {
            width: 100%;
          }
          .bottom-btn {
            margin-top: 0;
          }
        }
      }
    }
  }
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff;
  color: #000;
}

.react-datepicker__day--in-range {
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__navigation-icon::before {
  top: 12px;
}
