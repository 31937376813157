@use "../../../styles/_colors-variables.scss";

input.hook-form-input {
  height: 43px;
  padding: 0 16px;
  display: block;
  position: relative;
  border: 1px solid colors-variables.$lightBlue;
  border-radius: 8px;
}
