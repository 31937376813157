@import "../../../styles/colors-variables";

div.categories-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  span.category-title {
    font-size: 14px;
    font-weight: 700;
    color: $grayColor;
  }

  div.main-category {
    span.category-name {
      font-size: 16px;
    }
    span.gbp-category-name {
      font-size: 12px;
    }
  }

  div.sub-categories {
    span.category-name {
      font-size: 14px;
    }
    span.gbp-category-name {
      font-size: 10px;
    }
  }

  div.category-container {
    display: flex;
    flex-direction: column;
    span.gbp-category-name {
      color: $grayColor;
    }
  }
}
