@use "../../styles/_colors-variables.scss";

.question-module {
  position: relative;
  .icon-question {
    background: url("/assets/question.svg") center center no-repeat;
    background-size: 14px 14px;
    width: 16px;
    height: 16px;
    margin: 10px;
  }
  &:hover {
    .pink-tips {
      display: block;
    }
    .triangle {
      display: block;
    }
  }
  .pink-tips {
    display: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 28px;
    background: #f2947f;
    border-radius: 4px;
    padding: 4px 10px;
    font-family: "Mulish";
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    width: 190px;
    min-height: 38px;
    color: white;
    word-break: break-word;
    box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.25);
    z-index: 1;
    top: -48px;
    left: 50%;
    transform: translateX(-50%);

    &.top {
      bottom: 20px;
      top: auto;
      left: 50%;
      transform: translateX(-50%);
    }
    &.bottom {
      top: 31px;
      bottom: auto;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .triangle {
    display: none;
    content: "";
    position: absolute;
    left: 50%;
    margin-left: -8px;
    bottom: -6px;
    width: 0;
    height: 0;

    &.top {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 7px solid #f2947f;
      top: 3px;
    }
    &.bottom {
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-bottom: 7px solid #f2947f;
      border-top: 0;
      top: 24px;
    }
  }
}
