@use "../../../styles/_responsive-variables.scss";

.main-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 100%;
  margin-top: 10px;

  overflow: hidden;
  max-height: 1000px;

  &.collapse {
    max-height: 0;
    padding: 0;
    margin: 0;
  }

  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    font-size: 16px;
    line-height: 21px;
    max-height: 30px;
    margin-top: 5px;

    .review-date {
      font-weight: 700;
      color: #121863;
      font-family: "Noto Sans JP";
    }
  }

  .comment {
    margin-top: 8px;
    font-weight: 400;
    font-size: 18px;
    color: #121863;
    font-family: "Noto Sans JP";
    text-align: justify;
    overflow: scroll;
    white-space: pre-wrap;
    direction: ltr;
    line-height: 1.5;
    max-height: 1.5em * 4;
  }

  .translation-title {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
    font-family: "Noto Sans JP";

    .generate-by-ai {
      font-weight: 700;
      font-size: 16px;
      color: #000;
    }

    .translation {
      font-weight: 400;
      font-size: 16px;
      color: #121863;
    }
  }

  .reply-container {
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    height: 166px;
    border: 2px solid #d7dde7;
    vertical-align: top;
    font-size: 18px;
    overflow-wrap: break-word;
    text-align: left;
    word-break: break-all;
    white-space: pre-wrap;
    direction: ltr;
  }

  .bottom-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: fit-content;

    .ai-reply {
      font-weight: 700;
      font-size: 14px;
      color: #3241ff;
      font-family: "Noto Sans JP";
      cursor: pointer;
      background-color: #fff;
      border-color: #3241ff;
      height: 38px;
      width: fit-content;
      padding: 0px 10px;
      text-align: center;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      &:disabled {
        background-color: #cccccc;
        border-color: #cccccc;
        cursor: not-allowed;
      }
    }

    .sent {
      font-weight: 700;
      font-size: 14px;
      color: white;
      font-family: "Noto Sans JP";
      cursor: pointer;
      background-color: #3241ff;
      border-color: #3241ff;
      height: 38px;
      width: 86px;
      text-align: center;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        background-color: #cccccc;
        border-color: #cccccc;
        cursor: not-allowed;
      }
    }
  }

  .reviewer-name {
    margin-right: auto;
    align-self: center;
    color: #121863;
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .comment,
    .reply-container {
      font-size: 14px;
    }

    .reply-container {
      padding: 6px;
    }

    .bottom-container {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: fit-content;

      .ai-reply {
        font-weight: 700;
        font-size: 14px;
        color: #3241ff;
        font-family: "Noto Sans JP";
        cursor: pointer;
        background-color: #fff;
        border-color: #3241ff;
        height: 38px;
        width: fit-content;
        padding: 0px 10px;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        align-self: center;

        &:disabled {
          background-color: #cccccc;
          border-color: #cccccc;
          cursor: not-allowed;
        }
      }

      .sent {
        font-weight: 700;
        font-size: 14px;
        color: white;
        font-family: "Noto Sans JP";
        cursor: pointer;
        background-color: #3241ff;
        border-color: #3241ff;
        height: 38px;
        width: 86px;
        text-align: center;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;

        &:disabled {
          background-color: #cccccc;
          border-color: #cccccc;
          cursor: not-allowed;
        }
      }
    }

    .reviewer-name {
      margin-top: 5px;
      margin-right: auto;
      align-self: center;
    }
  }
}
