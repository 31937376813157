@use "../../../styles/_responsive-variables.scss";

.modal-default.modal-create-local-post {
  .top-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .modal-mains {
    padding: 40px 10px;
    height: unset;
    width: 750px;
    h2,
    h3 {
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;
      padding: 0px;
    }

    h3 {
      padding-left: 12px;
      font-size: 18px;
    }

    .modal-mains__body {
      display: flex;
      flex-direction: column;
      padding: 0px 30px;
      max-height: 80svh;
      overflow-y: auto;
      overflow-x: hidden;
      h2::after {
        background: none;
      }
    }
    .modal-confirm {
      .modal-mains {
        padding: 32px 40px;
      }
    }
  }

  .modal-confirm {
    align-items: center;
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .modal-mains {
      padding: 32px 8px;

      .modal-mains__body {
        padding: 0 8px;
      }

      .top-title {
        h2 {
          padding-left: 12px;
          font-size: 24px;
          &::after {
            display: none;
          }
        }
      }

      @include responsive-variables.responsiveBottomBtns;

      .bottom-btns {
        margin-top: 24px;
        flex-direction: column;
        align-items: stretch;
        div {
          flex-direction: column;
          align-items: stretch;
        }
      }
    }
  }
}

.create-local-post-warning {
  span {
    display: block;
    text-align: right;
    font-size: small;

    @media #{responsive-variables.$smartphone-breakpoint} {
      margin-top: 24px;
      text-align: center;
    }
  }
}
