div.hook-form-social-links-individual {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;

  label {
    width: 80px;
  }

  input {
    flex-grow: 1;
  }

  button.social-links-button {
    width: 24px;
    height: 24px;

    &.register {
      background: url(/assets/gray-plus.svg) center no-repeat;
      background-size: 20px 20px;
    }

    &.unregister {
      background: url(/assets/icon-trash.svg) center no-repeat;
      background-size: 20px 20px;
    }
  }
}
