@use "../../../styles/_colors-variables.scss";

div.hook-form-error {
  display: flex;
  align-items: center;
  gap: 4px;
  div.error-icon {
    background: url(/assets/warning.svg) center center no-repeat;
    display: inline-block;
    width: 15px;
    height: 15px;
  }
  span.error-message {
    color: colors-variables.$errorColor;
  }
}
