@use "../../../../styles/_colors-variables.scss";

div.individual-special-operation-hours {
  display: flex;
  flex-direction: row;
  gap: 12px;

  div.individual-special-operation-hours-date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;

    input.individual-special-operation-hours-date-input {
      height: 43px;
      padding: 0 16px;
      display: block;
      position: relative;
      border: 1px solid colors-variables.$lightBlue;
      border-radius: 8px;
      width: 160px;
    }
  }

  div.individual-special-operation-hours-time-periods {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    div.individual-special-operation-hours-time-period {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      span.individual-special-operation-hours-separator {
        font-size: 24px;
      }

      button.individual-special-operation-hours-remove-button {
        width: 24px;
        height: 24px;
        background: url(/assets/icon-trash.svg) center no-repeat;
        background-size: 20px 20px;
      }
    }

    button.individual-special-operation-hours-add-button {
      width: 24px;
      height: 24px;
      background: url(/assets/gray-plus.svg) center no-repeat;
      background-size: 20px 20px;
    }
  }
}
