@use '../../../styles/_colors-variables.scss';

.some-labels {
    .items {
        padding: 3px 12px 3px 12px;
        min-height: 24px;
        background: rgba(242, 148, 127, 0.1);
        border: 0.5px solid #F2947F;
        border-radius: 40px;
        display: inline-block;
        font-size: 12px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.015em;
        margin-right: 6px;
        color: #121863;
        margin-bottom: 10px;
        word-break: break-word;
        position: relative;
        .btn-close {                    
            width: 10px;
            height: 10px;
            background: #F2947F url('/assets/close.svg') 3px center no-repeat;
            background-size: 9px 9px;
            display: inline-block;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 7px;
        }
    }
    .add-items-wrap {
        position: relative;
        &.open {
            .popup-select-labels {
                display: block;
            }
        }
    }
    .add-items {
        margin-left: 10px;
        width: 79px;
        position: relative;
        &.open {
            .popup-select-labels {
                display: block;
            }
        }
        .btn-add {
            width: 16px;
            height: 19px;
            background: url('/assets/icon-edit-lilac.svg') center center no-repeat;
            background-size: 100%;
            border-radius: 100%;
            display: inline-block;
            margin-left: 0;
            margin-right: 3px;
            vertical-align: middle;
        }
        .txt {
            font-size: 13px;
            line-height: 17px;
            letter-spacing: 0.015em;
            color: #757799;
        }
    }
}