@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

// .summary-module
.summary-module {
  margin-bottom: 24px;
  .titles {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #121863;
    margin-bottom: 24px;
  }
  .summary-list {
    li {
      margin-bottom: 12px;
    }
    .txt {
      padding-left: 20px;
      font-weight: 400;
      font-size: 17px;
      line-height: 1.47;
      letter-spacing: 0.005em;
      color: #121863;
      position: relative;
      .points {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #3241ff;
        display: inline-block;
        margin-right: 5px;
        position: absolute;
        left: 5px;
        top: 12px;
      }

      @media #{responsive-variables.$smartphone-breakpoint} {
        font-size: responsive-variables.$sp-main-text-size;
      }
    }
  }
}
