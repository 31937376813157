@use "_responsive-variables.scss";
@use "_colors-variables.scss";

@media not #{responsive-variables.$smartphone-breakpoint} {
  .sp-only {
    display: none !important;
  }
}

@media #{responsive-variables.$smartphone-breakpoint} {
  .pc-only {
    display: none !important;
  }
}

/* nprogress style */
#nprogress {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  pointer-events: auto !important;
  background: rgba(0, 0, 0, 0.1);
  .bar {
    z-index: 10000 !important;
    height: 5px !important;
    position: fixed !important;
  }
  .spinner {
    position: fixed !important;
    top: calc(50% - 9px) !important;
    left: calc(50% - 9px) !important;
  }
}

/* common style */
body {
  background: #fbfbff;
  height: 100%;
}

* {
  white-space: normal;
}

input,
button {
  border-radius: 0;
}
a,
a:focus {
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
}
.icons,
.icon-before::before {
  vertical-align: middle;
  display: inline-block;
}
.icon-before::before {
  content: "";
  display: block;
  position: absolute;
}

.hide {
  display: none !important;
}

.flex {
  display: flex;
  align-items: center;

  @media #{responsive-variables.$smartphone-breakpoint} {
    flex-wrap: wrap;
    gap: 10px 0;
  }
}
.flex-grid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* btn */
.btn {
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  border: none;
  box-shadow: none;
  position: relative;
  text-transform: none;
  cursor: pointer;
  &.disabled {
    color: #fff;
  }
}

.btn.active,
.btn:active,
.btn:focus {
  box-shadow: none;
}

/* .btn-blue, .btn-red */
.btn-blue,
.btn-blue:hover,
.btn-blue:focus,
.btn-red,
.btn-red:hover,
.btn-red:focus {
  color: #fff;
  height: 40px;
  line-height: 38px;
  border-radius: 20px;
  width: auto;
  padding: 1px 20px;
  font-weight: 700;
}

.btn-blue {
  background: #3241ff !important;

  &:hover {
    opacity: 0.8;
  }

  &--decorative,
  &--fade {
    &:hover {
      opacity: 1 !important;
      cursor: auto;
    }
  }
}

.btn-blue--fade {
  background: #7b84ff !important;

  &:hover {
    opacity: 1 !important;
    cursor: auto;
  }
}

.btn-red {
  background: #f00 !important;
}

/* .btn-border */
.btn-border,
.btn-border:hover,
.btn-border:focus {
  color: #3241ff;
  height: 40px;
  line-height: 36px;
  border-radius: 20px;
  width: auto;
  padding: 1px 20px;
}

.btn-border {
  border: 1px solid #3241ff;

  &.disabled {
    color: #3241ff;
    opacity: var(--bs-btn-disabled-opacity);
  }
}

.btn-border-red {
  &,
  &:hover,
  &:focus {
    border-color: #f00;
    color: #f00;

    &.disabled {
      color: #f00;
    }
  }
}

.btn-border:hover {
  opacity: 0.8;
}

// .white-border-panel
.white-border-panel {
  background: #ffffff;
  border: 1px solid #c9d2df;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px 0 30px;
  // .padding32
  &.padding32 {
    padding: 32px;

    @media #{responsive-variables.$smartphone-breakpoint} {
      @include responsive-variables.spDefaultPadding;
    }
  }
}

// .content-wrap
.content-wrap {
  // padding-top: 64px;
  .right-content {
    padding: 30px 24px 100px 30px;
    flex: 1;
  }
}

@media #{responsive-variables.$smartphone-breakpoint} {
  .content-wrap {
    padding-top: 0;
    .right-content {
      padding: 20px 16px 130px 20px;
    }
  }
}

.loading {
  button,
  .btn,
  input,
  textarea,
  a[class*="btn-"] {
    pointer-events: none;
    opacity: 0.75;
    cursor: auto;
  }
}

.loading-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available !important;
  &.mh-355 {
    min-height: 355px;
    height: 355px !important;
  }
  &.mh-300 {
    min-height: 300px;
    height: 300px !important;
  }
  &.chart-row {
    position: absolute;
  }
}
