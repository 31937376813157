@use "../../../styles/_responsive-variables.scss";

.facility-list-header {
  button {
    border: 0;
    padding: 0;
    background: transparent;
    text-decoration: underline;
    font-weight: bold;
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    &.row-th {
      height: 16px;
    }

    > div.col-th {
      display: none;
    }
  }
}
