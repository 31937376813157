@use "../../../styles/_colors-variables.scss";

.media-upload {
  .error {
    padding: 8px 0 0 21px;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #c70000;
    position: relative;
    &:before {
      content: "";
      display: block;
      background: url(/assets/warning.svg) center center no-repeat;
      background-size: 15px 13px;
      width: 15px;
      height: 15px;
      position: absolute;
      left: 0px;
      top: 10px;
    }
  }

  &.single {
    .drag-drop-file-upload {
      flex: 1;
    }
    .drag-drop-file-upload-prompt {
      font-size: 14px;
      justify-content: space-around;
    }

    &.selected {
      .drag-drop-file-upload {
        display: none;
      }
    }
  }

  &.dragging-file {
    .drag-drop-file-upload {
      opacity: 0.5;
    }
  }
}

.media-upload-body {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
  height: 120px;
  overflow-y: auto;
}

.drag-drop-file-upload {
  height: 120px;
  width: 120px;
  border: dotted black 2px;
  border-radius: 16px;

  user-select: none;
  position: relative;

  &:hover {
    opacity: 0.5;
  }

  .drag-drop-file-upload-prompt {
    font-weight: bold;
    padding: 8px;
    text-align: center;
    font-size: 12px;
    inset: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;

    .icon-plus {
      background: url(/assets/gray-plus.svg) center no-repeat;
      background-size: 20px 20px;
      // background-color: red;
      width: 20px;
      height: 20px;
    }
  }

  input {
    inset: 0;
    position: absolute;
    opacity: 0 !important;
    cursor: pointer;
  }
}

.select-files-link {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-content: center;
}
