@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

.modal-default.modal-create-edit-chart {
  background-color: rgba(0, 0, 0, 0.2);
  .modal-mains {
    margin: 50px auto 50px;
    width: 1080px;
    height: auto;
    padding: 40px;

    &__body {
      max-height: 80vh;
      overflow: scroll;
    }
    .btn-close {
      width: 33px;
      height: 33px;
      &:hover {
        opacity: 0.9;
      }
    }
    .p-txt {
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      color: #121863;
      margin-bottom: 24px;
    }
    .bottom-btns {
      .btn {
        min-width: 160px;
      }
    }

    .top-title {
      .rights {
        .icon-del {
          background: url(/assets/blue-del.svg) center no-repeat;
          background-size: 24px 30px;
          width: 24px;
          height: 30px;
          margin-left: 32px;
        }
      }
    }
    .title-bar {
      margin-bottom: 55px;
      .label-title {
        color: #121863;
        font-weight: 400;
        letter-spacing: 0.005em;
        font-size: 17px;
      }
      .input-area {
        .inputs {
          height: 53px;
          input {
            color: #121863;
          }
        }
      }
    }
    .blue-status-bar {
      display: flex;
      padding-bottom: 16px;
      .blue-block {
        min-width: 73px;
        height: 34px;
        background: #121863;
        border-radius: 4px;
        line-height: 34px;
        text-align: center;
        margin-right: 10px;
        color: #fff;
        display: inline-block;
        font-weight: 700;
        font-size: 18px;
      }
      .txt {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: #757799;
        letter-spacing: 0.015em;
        &.red {
          color: #dc5b3e;
        }
      }
    }

    .items-group {
      padding-top: 6px;
      .choose-txt {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        color: #121863;
        margin-bottom: 24px;
        margin-bottom: 16px;
      }
    }

    @media #{responsive-variables.$smartphone-breakpoint} {
      @include responsive-variables.spDefaultPadding;
      @include responsive-variables.responsiveBottomBtns;
    }
  }
}
