@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

// .chart-category-item
.chart-category-item {
  margin-bottom: 16px;
  .titles {
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 16px;
    color: #121863;
  }
  .border-list {
    flex-wrap: wrap;
    .border-items {
      border: 1px solid #c9d2df;
      border-radius: 8px;
      padding: 10px 12px;
      margin-bottom: 24px;
      margin-right: 16px;
      display: flex;
      &.active,
      &:hover {
        border: 1px solid #f2947f;
        box-shadow: inset 0 0 0 1px #f2947f;
        background-color: rgba(242, 148, 127, 0.1);
      }
      &:last-child {
        margin-right: 0;
      }
      .left-txt {
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;
        color: #121863;
        max-width: 154px;
      }
      .icons {
        flex: 0 0 24px;
        max-width: 24px;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        &.icon-line-chart {
          background: url(/assets/show_chart.svg) center center no-repeat;
          background-size: 19px 17px;
        }
        &.icon-circle-chart {
          background: url(/assets/pie_chart.svg) center center no-repeat;
          background-size: 20px 20px;
        }
        &.icon-bar-chart {
          background: url(/assets/bar_chart.svg) center center no-repeat;
          background-size: 24px 24px;
        }
        &.icon-grid-chart {
          background: url(/assets/view_list_pink.svg) center center no-repeat;
          background-size: 24px 24px;
        }
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .border-list {
      .border-items {
        margin-bottom: 8px;
      }
    }
  }
}
