@use "../../../styles/_responsive-variables";
@use "../../../styles/_colors-variables.scss";

.facility-list .table-data {
  // Explicit spacing needed to ensure that that table's column sizes are consistent across pages
  grid-template-columns: 3fr 1.5fr 85px 130px 130px 1fr 1fr;

  &:has(.col-th.labels) {
    grid-template-columns: 3fr 1.5fr 85px 130px 130px 1fr 1fr 1.5fr;
  }
  .spacing {
    padding: 0 8px;
    word-break: break-word;
    .red-txt {
      font-weight: 700;
      font-size: 18px;
      color: #db303f;
      line-height: 26px;
    }
    ul {
      li {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #121863;
      }
    }
    .btn-blue {
      padding: 1px 30px;
      line-height: 36px;
      min-width: 146px;
      white-space: nowrap;
    }
  }
  .center {
    text-align: center;
  }
  .blue-txt {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: #121863;

    @media #{responsive-variables.$smartphone-breakpoint} {
      font-size: 15px;
    }
  }
  .blue-link {
    @extend .blue-txt;
    text-decoration-line: underline;
  }
  .row-th {
    background: rgba(50, 65, 255, 0.05);
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 8px;
    .width-buttons {
      min-width: 185px;
    }

    .change {
      margin-top: 2px;
      font-size: 11px;

      button {
        &::before {
          content: "(";
        }

        &::after {
          content: ")";
        }
        color: rgb(66, 66, 66);
      }
    }
  }
  .row-td {
    padding: 16px 8px;
    border-bottom: 1px solid #c9d2df;
    &:last-child {
      border-bottom: 0;
    }
    .col-td {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.005em;
      color: #121863;
    }
    .top-num {
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      text-align: right;
      letter-spacing: 0.005em;
      color: #121863;
    }
    .parent-num {
      letter-spacing: 0.005em;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      font-family: "Roboto";
      &.green {
        color: #7ac63f;
      }
      &.red {
        color: #dc5b3e;
      }
    }
  }
  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .sub-txt {
    color: #757799;
  }
  .width21 {
    width: 21%;
  }
  .width16 {
    width: 16%;
  }
  .width10 {
    width: 10%;
  }
  .width11 {
    width: 11%;
  }
  .width14 {
    width: 14%;
  }
  .width9 {
    width: 9%;
  }
  .font14 * {
    font-size: 14px;
  }
  .facility-title {
    text-align: left;
    > * {
      display: inline-block;
    }
    .btn {
      margin-top: 8px;
      padding: 0 12px;
      font-size: 10px;
      line-height: 2em;
      height: auto;
      min-width: 0;
    }
  }

  @media not #{responsive-variables.$smartphone-breakpoint} {
    .row-td {
      .sp-note {
        display: none;
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .row-td {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      .facility-title {
        flex: 0 0 calc(100% - 40px);
      }

      .score {
        position: relative;
        top: 4px;

        .spacing {
          display: flex;
          flex-direction: column;
          align-items: center;

          .letter-square {
            margin-right: 0;
          }

          .question-module {
            span.pink-tips {
              left: auto;
              right: -16px;
              bottom: 32px;
              transform: none;
            }
          }
        }
      }

      .muni,
      .category {
        width: auto;
      }

      .reviews-overview {
        display: flex;
        flex-direction: column;
      }

      .score {
        flex: 0 0 40px;
        max-width: 40px;
      }

      .profile-views,
      .direction-search-count {
        flex: 1 1 auto;
        width: 100%;
        order: 1;

        .spacing {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          .top-num {
            display: flex;
            width: calc(100% - 64px);
            justify-content: space-between;
            .sp-note {
              flex: 1 1 auto;
              text-align: left;
            }
          }
        }
      }

      .profile-views {
        margin-top: 8px;
      }

      .direction-search-count {
        margin-bottom: 4px;
      }

      .label {
        flex: 1 1 auto;
        order: 3;
        margin-top: 8px;

        .add-items-wrap {
          text-align: center;
        }
      }
    }
  }
}
