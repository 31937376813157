@use "../../../../../../styles/_responsive-variables.scss";

button.dlCsvButton {
  max-width: 320px;
  margin-bottom: 30px;
  height: auto;
  padding: 1px 20px;
  font-size: 14px;

  & + button {
    margin-left: 20px;
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    width: 100%;
    margin-bottom: 10px;
    padding: 1px;
    & + button {
      margin-left: 0;
    }
  }
}

.monthSection {
  & + & {
    margin-top: 30px;
  }

  .comparisonSection {
    margin: 24px 0;

    p {
      margin: 8px;

      span {
        width: 250px;
        display: inline-block;
        font-weight: bold;
      }
    }
  }

  hr {
    margin: 24px 0;

    &:first-child {
      display: none;
    }
  }
}
