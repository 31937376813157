@use "colors-variables";

@mixin buttonStyle {
    display: inline-block;
    background: colors-variables.$themeBlue;
    color: white;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 1px;
    border: 1px solid transparent;
    transition: 0.5s;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    border: none;
    box-shadow: none;
    position: relative;
    text-transform: none;
    cursor: pointer;

    &:not(.disabled):hover {
        border: 1px solid colors-variables.$themeBlue;
        background: white;
        color: colors-variables.$themeBlue;
    }
}