@use "../../styles/_responsive-variables.scss";
@use "../../styles/_colors-variables.scss";

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  45% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.line-button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  min-width: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;

  animation: wobble;
  animation-delay: 10s;
  animation-duration: 1.5s;
  transform-origin: center bottom;

  span {
    display: inline-flex;
    text-align: center;
    padding: 4px 2px;
    font-size: 12px;
    font-weight: 500;
    background-color: colors-variables.$lineColor;
    margin: -4px 0;
    border-radius: 6px;
    color: white;
  }

  a.line {
    background: {
      image: url(/assets/line-round.png);
      size: contain;
      repeat: no-repeat;
    }

    &,
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    bottom: 72px;
    right: 8px;
    span {
      font-size: 10px;
    }

    a.line {
      &,
      img {
        width: 45px;
        height: 45px;
      }
    }
  }
}
