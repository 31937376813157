@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

// .white-border-panel
.white-border-panel {
  &.account-information-module {
    padding: 32px;

    .title-margin {
      margin-top: 16px;
    }

    @media #{responsive-variables.$smartphone-breakpoint} {
      @include responsive-variables.spDefaultPadding;
      .row {
        flex-direction: column;
        .col {
          margin-top: 8px;
          width: 100%;
          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

// .account-information-module
.account-information-module {
  .title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.05em;
    color: #757799;
    display: block;
  }
  .center-num {
    font-weight: 400;
    font-size: 17px;
    line-height: 25px;
    align-items: center;
    color: #121863;
    margin-top: 4px;
  }
  .col {
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    &:last-child {
      .btn-border {
        float: right;
        margin-top: 5px;
        margin-right: -5px;
      }
    }
  }
  .btn {
    margin-left: 16px;
    .icons {
      width: 20px;
      margin-right: 10px;
      height: 18px;
    }
    .icon-edit {
      background: url("/assets/blue-edit.svg") center center no-repeat;
      background-size: 18px auto;
      margin-top: -2px;
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .center-num {
      margin-top: 0;
    }
  }
}
