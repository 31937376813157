@use "../../styles/_colors-variables.scss";

.dashboard {
  // .top-title-bar
  .top-title-bar {
    padding-bottom: 34px;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
    .rights {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .flex {
        flex: 0 0 100%;
        justify-content: flex-end;
        margin-bottom: 10px;
        .profile-completion-level {
          font-family: "Noto Sans JP";
          font-weight: 700;
          font-size: 24px;
          margin-right: 10px;
        }
      }
      .btn-border {
        flex: 0 0 auto;
        display: flex;
        font-size: 24px;
        font-weight: 400;
        line-height: 37px;
        height: 37px;
      }
    }
  }
}

.top-section-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dashboard-group {
  margin-bottom: 40px;
  .group-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    letter-spacing: -0.005em;
    color: #121863;
    margin-bottom: 16px;
    .line {
      margin-left: 15px;
      flex: 1;
      height: 4px;
      background: #c9d2df;
    }
  }
  .status-hint {
    color: #121863;
  }
  .hints {
    color: #db303f;
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    margin-left: 20px;
  }
  .two-panel {
    .row {
      margin: 0 -12px 25px -12px;
      flex-direction: column;
      height: 100vh;
      .col {
        padding: 0 12px;
        position: relative;
        margin-bottom: 1vw;
        break-inside: avoid;
      }
    }
    .col {
      .white-border-panel {
        min-height: 405px;
        position: relative;
      }
      .panel-wrap {
        margin-bottom: 24px;
      }
    }
  }
}
