@use '../../../styles/_colors-variables.scss';

// .suggestions-filter-bar
.suggestions-filter-bar {
    .right-link {
        display: flex;
        justify-content: flex-end;
        .blue-link {
            color: #3241FF;
            font-size: 16px;
            font-weight: 700;
            text-decoration: none;
        }
        .icon-arrow {
            margin-left: 5px;
            background: url(/assets/blue-right-arrow.svg) center center no-repeat;
            background-size: 11px 10px;
            width: 10px;
            height: 9px;
            margin-top: -3px;
        }
    }
    .update-wrap {
        .update-title-txt {
            font-weight: 400;
            font-size: 13px;
            line-height: 19px;
            color: #121863;
            font-family: 'Noto Sans JP';
            padding-left: 25px;
        }
        .line-bar {
            width: 100%;
            height: 3px;
            background: #C9D2DF;
            margin: 8px 0 48px;
            position: relative;
        }
        .center-area {
            background-color: #FBFBFF;
            padding: 0 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: -17px;
            width: 422px;
            .left-date {
                font-weight: 700;
                font-size: 24px;
                line-height: 35px;
                display: flex;
                align-items: center;
                color: #121863;
                margin-right: 16px;
            }
            .right-dropdown {
                flex: 0 0 auto;
                max-width: fit-content;
                .inputs {
                    height: 32px;
                    border-radius: 16px;
                    background-color: #121863;                    
                }
            }
        }
    }
}