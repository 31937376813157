@use "../../../styles/_responsive-variables.scss";
@use "../../../styles/_colors-variables.scss";

// .facility-registration-list-module
.facility-registration-list-module {
  position: relative;
  border-radius: 16px;
  &.have-list {
    .empty-list {
      display: none;
    }
  }
  .empty-list {
    background: rgba(255, 255, 255, 0.7);
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 55;
    .txt {
      font-weight: 700;
      font-size: 20px;
      line-height: 29px;
      color: #121863;
      margin-bottom: 16px;
    }
  }
  .table-area {
    border: 1px solid #c9d2df;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    min-height: 350px;
    .top-edit {
      display: flex;
      justify-content: flex-end;
      padding: 12px 26px 24px 0;
      .btn-blue {
        min-width: 146px;
      }
    }
    .list-wrap {
      .row-td,
      .row-th {
        display: table;
        width: 100%;
        padding: 0 8px;
      }
      .row-th {
        height: 37px;
      }
      .row-td {
        min-height: 54px;
        border-bottom: 1px solid #c9d2df;
        &:last-child {
          border-bottom: 0;
        }
      }
      .col-th,
      .col-td {
        display: table-cell;
        vertical-align: middle;
      }
      .col-td {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        color: #121863;
      }
      .col-th {
        font-weight: 700;
        font-size: 12px;
        line-height: 17px;
        color: #121863;
      }
      .spacing {
        padding: 2px 8px;
      }
      .pl10 {
        padding-left: 10px+8px;
      }
      .pl18 {
        padding-left: 18px+8px;
      }
      .pl22 {
        padding-left: 22px+8px;
      }

      @media not screen and (max-width: 767px) {
        .w30 {
          width: 30%;
        }
        .w20 {
          width: 20%;
        }
      }

      .row-th {
        background: rgba(50, 65, 255, 0.05);
      }
      .bold {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #121863;
      }
      .color-txt {
        padding-right: 40px;
        font-weight: 700;
        font-size: 17px;
        line-height: 25px;
        display: inline-block;
        max-width: 123px;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 3px;
          background-color: #c4c4c4;
          height: 21px;
          position: absolute;
          right: -5px;
          top: 50%;
          margin-top: -10px;
        }
        &.green {
          color: #7fc008;
        }
        &.yellow {
          color: #f7c30e;
        }
        &.red {
          color: #db303f;
        }
      }
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .table-area {
      .top-edit {
        padding: 0;
        .btn {
          @include responsive-variables.responsiveFixedBottomBtn;
        }
      }

      .list-wrap {
        .row-th,
        .row-td {
          display: flex;
          flex-wrap: wrap;
          padding: 8px 0;
          align-items: center;
        }
      }

      .col-th,
      .col-td {
        *,
        .color-txt {
          padding: 0;

          &::after {
            display: none;
          }
        }
      }

      .loc-name {
        flex: 0 0 calc(100% - 90px);
      }

      .status {
        flex: 0 0 90px;

        .spacing {
          text-align: right;

          .color-txt {
            font-size: responsive-variables.$sp-main-text-size;
          }
        }
      }

      .table-header {
        .muni,
        .category {
          display: none;
        }
      }

      .table-body {
        .row-td {
          .muni,
          .category {
            font-size: 12px;
            line-height: 1.25;
          }
        }
      }
    }
  }
}
