@use "../../../styles/_colors-variables.scss";

// .modal-assign-org
.modal-default.modal-edit-feaetures {
  background-color: rgba(0, 0, 0, 0.2);

  .modal-mains {
    margin: 0px auto 50px;
    width: 630px;
    height: auto;
    padding: 40px;

    .reset-setting-hint {
      color: #121863;
      height: 30px;
      width: 100%;
      border-bottom: 1px solid black;
      padding-bottom: 40px;
      margin-bottom: 30px;
      font-weight: 400;
      font-size: 17px;
    }

    .btn-close {
      width: 32px;
      height: 32px;

      &:hover {
        opacity: 0.9;
      }
    }

    .icon-trash {
      position: absolute;
      right: 15px;
      top: 40px;
      background: url(/assets/icon-trash.svg) center no-repeat;
      background-size: 20px 20px;
      width: 30px;
      height: 30px;
      cursor: pointer;

      &:hover {
        background: url(/assets/icon-trash-blue.svg) center no-repeat;
      }
    }

    .bottom-btns {
      margin-top: 20px;
    }
  }
}
