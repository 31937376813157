div.daily-operation-hours {
  display: flex;
  flex-direction: column;

  width: fit-content;

  div.daily-operation-hours-inputs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;

    div.daily-operation-hours-checkbox-container {
      display: flex;
      flex-direction: column;
      label {
        font-size: 18px;
      }
    }

    div.daily-operation-hours-time-periods {
      display: flex;
      flex-direction: column;
      gap: 4px;

      div.daily-operation-hours-time-period-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        div.daily-operation-hours-time-period {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          span.daily-operation-hours-separator {
            font-size: 24px;
          }
        }

        button.daily-operation-hours-add-button {
          width: 24px;
          height: 24px;
          background: url(/assets/gray-plus.svg) center no-repeat;
          background-size: 20px 20px;
        }

        button.daily-operation-hours-remove-button {
          width: 24px;
          height: 24px;
          background: url(/assets/icon-trash.svg) center no-repeat;
          background-size: 20px 20px;
        }
      }
    }
  }

  .daily-operation-hours-checkbox {
    width: 160px;
  }

  .daily-operation-hours-error {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
}
