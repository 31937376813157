@use '../../styles/_colors-variables.scss';

.delete-google-link {
    // .top-title-bar
    .top-title-bar {
        padding-bottom: 34px;
        flex-wrap: wrap;
        .left-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            color: #121863;
        }
    }

    .delete-google-link {
        &__main {
            padding: 0 16px;

            h3 {
                margin-bottom: 1em;
                font-size: 1.25em;
            }

            &__form {
                display: flex;
                align-items: center;

                .inputs {
                    margin-right: 1em;
                }
            }
        }

        &__success-message {
            font-weight: bold;
        }
    }
}