@use "../../../styles/_responsive-variables.scss";

.confirm-content {
  ul.location-names-list {
    list-style: disc;

    li {
      list-style: disc;
      list-style-position: inside;
    }
  }
}

.create-local-post-bottom-btns {
  margin-top: 10px !important;
  justify-content: space-between !important;
  gap: 8px;
  div {
    display: flex;
    gap: 8px;
  }
}

.create-local-post-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .margin-bottom-30 {
    margin-bottom: 30px;
  }
  .inputs-wrap {
    width: 200px;
  }

  .url-wrapper {
    padding-bottom: 30px;
  }

  .media-upload-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .event {
    display: flex;
    flex-direction: column;
    gap: 0px;

    .inputs.error {
      padding: 8px 0 0 21px;
    }
  }

  .offer {
    display: flex;
    flex-direction: column;
    gap: 0px;

    .inputs.error {
      padding: 8px 0 0 21px;
    }
  }

  .message-body {
    .error {
      padding: 8px 0 0 21px;
      font-weight: 700;
      font-size: 12px;
      line-height: 17px;
      display: flex;
      align-items: center;
      letter-spacing: 0.015em;
      color: #c70000;
      &:before {
        content: "";
        display: block;
        background: url(/assets/warning.svg) center center no-repeat;
        background-size: 15px 13px;
        width: 15px;
        height: 15px;
        left: 0px;
        top: 10px;
      }
    }
  }

  .call-to-action {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .inputs {
      margin: 0 !important;
    }
  }

  .message-body-input {
    padding: 0;
    display: flex;
    justify-content: stretch;
    align-items: stretch;

    textarea {
      padding: 8px 16px;
      height: unset;
      line-height: 125%;
    }
  }

  .post-to-instagram-wrapper {
    position: relative;
    padding: 8px;

    .post-to-instagram {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 0px 10px;

      .post-to-instagram-call-to-action-note {
        font-size: 12px;
        letter-spacing: 0.015em;
        padding: 0 10px;
        color: #c70000;
      }
    }
  }

  .disable-overlay {
    position: absolute;
    inset: 0;
    background: rgba(40, 40, 40, 0.4);
    color: white;
    font-weight: bold;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .inputs-wrap,
    .inputs {
      width: 100%;
    }

    .top-title {
      align-items: center;

      .inputs {
        // Post type dropdown
        width: 160px;
      }
    }

    .event {
      gap: 4px;
    }
  }
}
