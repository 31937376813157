@use "../../styles/_colors-variables.scss";

.modal-default {
  &.modal-confirm {
    .modal-mains {
      width: 600px !important;
      height: auto;
      .description-txt {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        margin: 50px 0;
        white-space: break-spaces;
      }
      .confirm-content-container {
        max-height: 50vh;
        overflow-y: auto;
      }
    }
    .bottom-btns {
      gap: 8px;
    }
  }
}
