@use "../../styles/_responsive-variables.scss";
@use "../../styles/_colors-variables.scss";

.facility-registration {
  // .top-title-bar
  .top-title-bar {
    padding-bottom: 20px;
    .left-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 130%;
      color: #121863;
    }
  }

  @media #{responsive-variables.$smartphone-breakpoint} {
    .content-wrap &.right-content {
      padding-bottom: 160px;
    }
  }
}
