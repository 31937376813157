@use '../../styles/_colors-variables.scss';

.facility-list {
    // .top-title-bar
    .top-title-bar {
        padding-bottom: 34px;
        .left-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 130%;
            color: #121863;
        }
        .rights {
            .btn {
                margin-left: 16px;
                .icons {
                    width: 20px;
                    margin-right: 10px;
                    height: 18px;
                }
                .icon-pdf {
                    background: url('/assets/print.svg') center center no-repeat;
                    background-size: 20px 24px;
                }
                .icon-csv {
                    background: url('/assets/view_list.svg') center center no-repeat;
                    background-size: 24px 24px;
                }
            }
        }
    }

    small.footnote {
        padding: 16px;
        text-align: left;
    }
}