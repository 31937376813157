@use '../../../styles/_colors-variables.scss';

// .white-border-panel 
.white-border-panel {
  &.dashboard-facility-overview-module {
    padding: 44px 100px 30px 56px;
  }
}

// .dashboard-facility-overview-module
.dashboard-facility-overview-module {
    .col {
        text-align: center;
    }
    .top-area {
        margin-bottom: 30px;
        min-height: 47px;
        .title {
            font-weight: 700;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.0015em;
            color: #121863;
            margin-bottom: 2px;
        }
        .littles {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.015em;
            color: #121863;
        }
    }
    .center-num {
        margin-bottom: 16px;
        .icons {
            width: 20px;
            height: 20px;
            &.icon-done {
                background: rgba(122,198,63,0.3) url(/assets/green-done.svg) center center no-repeat;
                background-size: 20px;
                border-radius: 100%;
            }
            &.icon-info {
                background: url(/assets/info.svg) center center no-repeat;
                background-size: 23px;
            }
            &.icon-error {
                background: url(/assets/error.svg) center center no-repeat;
                background-size: 20px;
            }
            &.icon-no-data {
                background: url(/assets/no-data.svg) center center no-repeat;
                background-size: 20px;
            }
        }
        .num {
            vertical-align: middle;
            display: inline-block;
            font-family: 'Roboto';
            font-weight: 700;
            font-size: 32px;
            line-height: 38px;
            letter-spacing: -0.015em;
            color: #121863;
            margin-left: 5px;
        }
    }
    .btn-border,
    .btn-border:hover,
    .btn-border:focus {
        height: 24px;
        line-height: 20px;
        font-size: 12px;
        min-width: 100px;
    }
    .chart-one {
        position: relative;
        width: 200px;
        height: 200px;
        .pie-chart-container {
            position: absolute;
        }
    }
}