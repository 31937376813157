@use "../../../styles/_responsive-variables.scss";

.modal-default {
  &.modal-unreplied-reviews {
    .modal-mains {
      width: 700px !important;
      height: auto;
      .btn-close {
        cursor: pointer;
      }
      .description-txt {
        font-weight: 400;
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        margin: 50px 0;
        white-space: break-spaces;
      }

      .title {
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: black;
        margin-bottom: 20px;
      }

      .review-container {
        max-height: 600px;
        height: fit-content;
        width: 100%;
        padding: 10px;
        overflow-y: auto;

        .no-comment {
          text-align: center;
          margin-top: 10px;
        }

        .loading {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
          font-size: 20px;
        }
      }

      @media #{responsive-variables.$smartphone-breakpoint} {
        @include responsive-variables.spDefaultPadding;

        .review-container {
          max-height: 65vh;
          padding: 10px 4px 10px 0;
        }

        .bottom-btns {
          margin-top: 0px;
        }
      }
    }
  }
}
