@use "responsive-variables";

.top-scrollbar {
  overflow-x: scroll;
  overflow-y: hidden;
  transform: rotateX(180deg);
}
.table-container {
  overflow-x: scroll;
  overflow-y: hidden;
}

.table-data {
  width: auto;
  min-width: 100%;
  display: inline-table;
  &.scrollable {
    transform: rotateX(-180deg);
    .col-th,
    .col-td {
      min-width: 160px;
    }
  }

  .row-th,
  .row-td {
    display: table;
    width: 100%;
  }

  .col-td,
  .col-th {
    display: table-cell;
    vertical-align: middle;
  }

  .buttons-section .spacing {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    > .btn + .btn {
      margin-top: 8px;
    }
  }

  .spacing {
    padding: 0 8px;
    word-break: break-all;
    .red-txt {
      font-weight: 700;
      font-size: 18px;
      color: #db303f;
      line-height: 26px;
    }
    ul {
      li {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        color: #121863;
      }
    }
    .btn-blue {
      padding: 1px 30px;
      line-height: 36px;
      min-width: 146px;
      white-space: nowrap;
    }
  }
  .center {
    text-align: center;
  }

  .blue-txt {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.015em;
    color: #121863;

    @media #{responsive-variables.$smartphone-breakpoint} {
      font-size: responsive-variables.$sp-main-text-size;
    }
  }

  .blue-link {
    @extend .blue-txt;
    text-decoration-line: underline;
  }

  .row-th {
    background: rgba(50, 65, 255, 0.05);
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    height: 48px;
    padding: 0 8px;
    .width-buttons {
      min-width: 185px;
    }
  }

  .row-td {
    padding: 16px 8px;
    border-bottom: 1px solid #c9d2df;
    &:last-child {
      border-bottom: 0;
    }
    .col-td {
      font-size: 17px;
      line-height: 25px;
      letter-spacing: 0.005em;
      color: #121863;
    }
  }

  .text-left {
    text-align: left;
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .sub-txt {
    color: #757799;
  }
  .width21 {
    width: 21%;
  }
  .width16 {
    width: 16%;
  }
  .width10 {
    width: 10%;
  }
  .width11 {
    width: 11%;
  }
  .width12 {
    width: 12%;
  }
  .width13 {
    width: 13%;
  }
  .width14 {
    width: 14%;
  }
  .width9 {
    width: 9%;
  }
  .font14 * {
    font-size: 14px;
  }

  @media screen and (min-width: 768px) {
    .row-td {
      .sp-note {
        display: none;
      }
    }
  }
}

.table-data-grid {
  display: grid;

  .row-th {
    height: auto;
  }

  .row-th,
  .row-td {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: subgrid;
    align-items: center;

    &.sub-txt {
      grid-column: 1 / -1;
    }

    .col-th,
    .col-td {
      min-width: 0;
    }
  }

  &-pc-only {
    @media #{responsive-variables.$smartphone-breakpoint} {
      display: block;

      .row-th,
      .row-td {
        display: flex;
      }
    }
  }
}
